import auth from '../middleware/auth';
import verified from '../middleware/verified';
import DefaultLayout from "@/components/layouts/defaultLayout/Default.vue";

const routes = [
    {
        path: '/users',
        component: DefaultLayout,
        children: [
            {
                path: '/users',
                name: 'users.index',
                component: () => import('../views/users/Users.vue'),
                meta: {
					middleware: [auth, verified]
				}
            }
        ]
    }
];

export default routes;