import auth from '@/middleware/auth';
import verified from '@/middleware/verified';

const routes = [
    {
        path: '/legal',
        component: () => import('../../components/layouts/defaultLayout/Default.vue'),
        children: [
            {
                path: '/legal',
                name: 'legal.index',
                component: () => import('../../views/admin/legalElement/LegalElement.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/poles/create',
                name: 'poles.create',
                component: () => import('../../views/admin/poles/CreatePole.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
        ]
    }
];

export default routes;