export default {
    namespaced: true,
    state: {
		isError: false,
		openCreateProductSheet: false,
		openEditProductSheet: false,
		idProductSheet: null,
		niua: null,
		showMessageSuccess: false,
		reloadData: false,
		productSheet: null,
	},
	getters: {
		getOpenCreateProductSheet(state) {
			return state.openCreateProductSheet
		},
		getOpenEditProductSheet(state) {
			return state.openEditProductSheet
		},
		getError(state) {
			return state.isError
		},
		getIdProductSheet(state) {
			return state.idProductSheet
		},
		getShowMessageSuccess(state)  {
			return state.showMessageSuccess
		},
		getReloadData(state) {
			return state.reloadData
		},
		getProductSheet(state) {
			return state.productSheet
		}
	},
	mutations: {
		SET_OPEN_NEW_PRODUCT_SHEET(state, payload) {
			state.openCreateProductSheet = payload
		},
		SET_OPEN_EDIT_PRODUCT_SHEET(state, payload) {
			state.openEditProductSheet = payload
		},
		SET_ERROR(state, payload) {
			state.isError = payload
		},
		SET_ID_PRODUCT_SHEET(state, payload) {
			state.idProductSheet = payload
		},
		SET_SHOW_MESSAGE(state, payload) {
			state.showMessageSuccess = payload
		},
		SET_RELOAD_DATA(state, payload) {
			state.reloadData = payload
		},
		SET_PRODUCT_SHEET(state, payload) {
			state.productSheet = payload
		}
	},
	actions: {},
}