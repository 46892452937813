import { createRouter, createWebHistory } from "vue-router";
import authRoutes from './authRoutes';
import generalRoutes from './generalRoutes';
import structuresRoutes from './structuresRoutes'
import fundsRoutes from './fundsRoutes'
import polesRoutes from './admin/polesRoutes'
import productsRoutes from './admin/productsRoutes'
import secteursRoutes from './admin/secteursRoutes'
import legalRoutes from './admin/legalRoutes'
import usersRoutes from './usersRoutes'
import projectsRoutes from './projectsRoutes'

const allRoutes = [];
const routes = allRoutes.concat(
  generalRoutes, 
  authRoutes,
  structuresRoutes,
  fundsRoutes,
  polesRoutes,
  productsRoutes,
  secteursRoutes,
  usersRoutes,
  legalRoutes,
  projectsRoutes
)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPositions) {
		return { x: 0, y: 0}
	}
});

function nextFactory(context, middleware, index) {
	const subsequentMiddleware = middleware[index];
	if (!subsequentMiddleware) return context.next;

	return (...parameters) => {
		context.next(...parameters);
		const nextMiddleware = nextFactory(context, middleware, index, 1);
		subsequentMiddleware({ ...context, next: nextMiddleware });
	};
}

router.beforeEach((to, from, next) => {
	if (to.meta.middleware) {
		const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [ to.meta.middleware ];

		const context = {
			from,
			next,
			router,
			to
		};
		const nextMiddleware = nextFactory(context, middleware, 1);

		return middleware[0]({ ...context, next: nextMiddleware });
	}

	return next();
});

export default router;
