<template>
    <div>
        <footer class="footer footer-static footer-light">
          <p class="clearfix mb-0"><span class="float-md-start d-block d-md-inline-block mt-25">COPYRIGHT © 2023<a class="ms-25" href="https://1.envato.market/pixinvent_portfolio" target="_blank">Foda</a><span class="d-none d-sm-inline-block">, Tout droit reservé</span></span><span class="float-md-end d-none d-md-block">Faley - Platefore de soumission<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg></span></p>
      </footer>
    </div>
</template>
<script>
export default {
    
}
</script>