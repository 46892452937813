import auth from '../middleware/auth';
import verified from '../middleware/verified';

const routes = [
    {
        path: '/structures',
        component: () => import('../components/layouts/defaultLayout/Default.vue'),
        children: [
            {
                path: '/structures',
                name: 'structures.index',
                component: () => import('../views/structures/ListeStructures.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/structures/create',
                name: 'structures.create',
                component: () => import('../views/structures/CreateStructure.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/structures/:niua',
                name: 'structures.show',
                component: () => import('../views/structures/ShowStructure.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/structures/:niua/edit',
                name: 'structures.edit',
                component: () => import('../views/structures/EditStructure.vue'),
                meta: {
					middleware: [auth, verified]
				}
            }
        ]
    }
];

export default routes;