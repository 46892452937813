<template>
    <div>
        <!-- BEGIN: Header-->
		<Header />
		<!-- END: Header-->


		<!-- BEGIN: Main Menu-->
		<SideMenu />
		<!-- END: Main Menu-->

		<!-- BEGIN: Content-->
		<div class="app-content content d-flex flex-column min-vh-100 ">
			<div class="content-overlay"></div>
			<div class="header-navbar-shadow"></div>
			<div class="content-wrapper container-xxl p-0">
				<div class="content-header row">
				</div>
				<div class="content-body">
					<router-view></router-view>
				</div>
			</div>
		</div>
		<!-- END: Content-->

		<Footer />
    </div>
</template>
<script>
import Header from './partials/Header.vue'
import Footer from './partials/Footer.vue'
import SideMenu from './partials/SideMenu.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
    name: "Default",
    components: {
        Header,
        Footer,
        SideMenu
    },
	setup() {
		const store = useStore();
		const loading = computed(() => store.state.loading)
		return {
			loading
		}
	}
}
</script>