import AuthLayout from '../components/layouts/authLayout/AuthLayout.vue';
import guest from '../middleware/guest'

const routes = [
	{
		path: '/auth',
		component: AuthLayout,
		children: [
			{
				path: '/login',
				name: 'login',
				component: () => import('../views/Auth/Login.vue'),
				meta: {
					middleware: guest
				}
			},
			{
				path: '/register',
				name: 'register',
				component: () => import('../views/Auth/Register.vue'),
				meta: {
					middleware: guest
				}
			},
			{
				path: '/confirm-registration-code',
				name: 'confirmRegister',
				component: () => import('../views/Auth/ConfirmRegister.vue'),
				meta: {
					middleware: guest
				}
			},
			{
				path: '/forgot-password',
				name: 'forgotPassword',
				component: () => import('../views/Auth/ForgotPassword.vue'),
				meta: {
					middleware: guest
				}
			},
			{
				path: '/new-password',
				name: 'newPassword',
				component: () => import('../views/Auth/NewPassword.vue'),
				meta: {
					middleware: guest
				}
			},
			{
				path: '/confirmation-code',
				name: 'confirmCode',
				component: () => import('../views/Auth/ConfirmCode.vue'),
				meta: {
					middleware: guest
				}
			}
		]
	}
];

export default routes;
