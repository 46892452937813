import auth from '../middleware/auth';
import verified from '../middleware/verified';

const routes = [
    {
        path: '/projects',
        component: () => import('../components/layouts/defaultLayout/Default.vue'),
        children: [
            {
                path: '/projects',
                name: 'projects.index',
                component: () => import('../views/projects/ListProjects.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/projects/add',
                name: 'projects.add',
                component: () => import('../views/projects/AddProject.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/projects/:id/detail',
                name: 'projects.show',
                component: () => import('../views/projects/ShowProject.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            // {
            //     path: '/structures/:niua/edit',
            //     name: 'structures.edit',
            //     component: () => import('../views/structures/EditStructure.vue'),
            //     meta: {
			// 		middleware: [auth, verified]
			// 	}
            // }
        ]
    }
];

export default routes;