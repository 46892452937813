export default {
    namespaced: true,
    state: {
		isError: false,
		openCreateEntreprise: false,
		openEditEntreprise: false,
		openEditLogo: false,
		idEntreprise: null,
		niua: null,
		showMessageSuccess: false,
		reloadData: false,
		entreprise: null,
		entreprisesList: null,
		regions: null,
		sectors: null,
		legalStatus: null
	},
	mutations: {
		SET_OPEN_NEW_ENTREPRISE(state, payload) {
			state.openCreateEntreprise = payload
		},
		SET_OPEN_EDIT_ENTREPRISE(state, payload) {
			state.openEditEntreprise = payload
		},
		SET_ERROR(state, payload) {
			state.isError = payload
		},
		SET_ID_ENTREPRISE(state, payload) {
			state.idEntreprise = payload
		},
		SET_SHOW_MESSAGE(state, payload) {
			state.showMessageSuccess = payload
		},
		SET_RELOAD_DATA(state, payload) {
			state.reloadData = payload
		},
		SET_RELOAD_USER(state, payload) {
			state.reloadUser = payload
		},
		SET_ENTREPRISE(state, payload) {
			state.entreprise = payload
		},
		SET_NUIA(state, payload) {
			state.niua = payload
		},
		SET_ENTREPRISES_LIST(state, payload) {
			state.entreprisesList = payload
		},
		SET_REGIONS(state, payload) {
			state.regions = payload
		},
		SET_SECTORS(state, payload) {
			state.sectors = payload
		},
		SET_LEGAL_STATUS(state, payload) {
			state.legalStatus = payload
		},
		SET_OPEN_EDIT_LOGO(state, payload) {
			state.openEditLogo = payload
		}
	},
	actions: {},
}