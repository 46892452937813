import auth from '../../middleware/auth';
import verified from '../../middleware/verified';

const routes = [
    {
        path: '/poles',
        component: () => import('../../components/layouts/defaultLayout/Default.vue'),
        children: [
            {
                path: '/poles',
                name: 'poles.index',
                component: () => import('../../views/admin/poles/Poles.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/poles/create',
                name: 'poles.create',
                component: () => import('../../views/admin/poles/CreatePole.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
        ]
    }
];

export default routes;