export default {
    namespaced: true,
    state: {
		openEditPole: false,
		openAddPrefecture: false,
		pole: null,
		poles: [],
		reloadPole: false,

		openAddDocumentType: false,
		reloadDocumentType: false,

		openAddLegalStatus: false,

		openAddJob: false,
		
		prefectures: [],
		reloadData: false,
	},
	mutations: {
		SET_GET_POLES_VALUE(state, payload) {
			state.poles = payload
		},
		SET_GET_PREFECTURES_VALUE(state, payload) {
			state.prefectures = payload
		},
		SET_RELOAD_DATA(state, payload) {
			state.reloadData = payload
		},
		SET_RELOAD_POLE(state, payload) {
			state.reloadPole = payload
		},
		SET_OPEN_EDIT_POLE(state, payload) {
			state.openEditPole = payload
		},
		SET_POLE_VALUE(state, payload) {
			state.pole = payload
		},
		SET_OPEN_ADD_PREFECTURE(state, payload) {
			state.openAddPrefecture = payload
		},

		SET_OPEN_ADD_DOCUMENT_TYPES(state, payload) {
			state.openAddDocumentType = payload
		},
		SET_RELOAD_DOCUMENT_TYPE(state, payload) {
			state.reloadDocumentType = payload
		},

		SET_OPEN_ADD_LEGAL_STATUS(state, payload) {
			state.openAddLegalStatus = payload
		},

		// Job 
		SET_OPEN_ADD_JOB(state, payload) {
			state.openAddJob = payload
		},
	},
	actions: {},
}