import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueNumberFormat from 'vue-number-format';

// axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
axios.defaults.baseURL = "https://dev-faley.infovbg-guinee.org/api/";
axios.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('_token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
            // config.headers['Accept'] = 'application/json';
            // config.headers['Content-Type'] = 'application/json';
            // config.headers['Content-Type'] = 'multipart/form-data';
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

createApp(App)
    .use(store)
    .use(router)
    .use(VueSweetalert2)
    .use(VueNumberFormat, { precision: 0, thousand: ' ', suffix: ' GNF', prefix: '', decimal: ''})
    .mount("#app");
