<template>
    <div>
        <div class="app-content">
        <div class="content-overlay"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-cover">
                        <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    setup() {

    }
}
</script>