import { ref, inject } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

export default function useAuth() {
	const loading = ref(false);
	const formData = ref(null);
    const errorMessage = ref(null);
    const userId = ref(null);
    const user = ref(null);
    const errors = ref(null);
    const isValid = ref(false);
    const store = useStore();
    const router = useRouter();
    const successMessage = ref(null);
    const showMessage  = ref(false);
    const swal = inject('$swal')

	const onLogin = async () => {
		try {
			loading.value = true;
			let response = await axios.post('/login', formData.value);
			if (response.status === 200) {
                loading.value = false;
                sessionStorage.setItem('_token', response.data.data.access_token)
                sessionStorage.setItem('userInfo', JSON.stringify(response.data.data.user))
                sessionStorage.setItem('email', response.data.data.user.email)
                if (!response.data.data.user.email_verified_at) {
                    return router.push({ name: 'ConfirmRegister' });
                }
                swal.fire({
                    icon: 'success',
                    type: 'success',
                    toast: true,
                    position: 'top-right',
                    title: 'Connexion....',
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true
                })
                return location.reload()
			}
		} catch (error) {
			loading.value = false;
            store.commit('SET_ERROR', true)
			if (error.response.status === 401) {
				errorMessage.value = error.response.data.message;
			} 
            else if (error.response.status === 403) {
                errorMessage.value = error.response.data.message;
                swal.fire(
                    'Erreur!',
                    errorMessage.value,
                    'warning'
                )
            }
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
				errorMessage.value = 'Erreur de traitement, vueillez réessayer plus tard.';
			}
		}
	};

    const onRegister = async () => {
        try {
            loading.value = true;
            let response = await axios.post("/register", formData.value)
            if (response.status === 200) {
                loading.value = false;
                sessionStorage.setItem('_token', response.data.data.access_token)
                sessionStorage.setItem('userInfo', JSON.stringify(response.data.data.user))
                sessionStorage.setItem('email', response.data.data.user.email)
                router.push({ name: 'confirmRegister' })
                console.log(response.data)
            }
        } catch (error) {
            loading.value = false
            store.commit('SET_ERROR', true)
            if(error.response.status === 401) {
                errorMessage.value = error.response.data.message
            }
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
                errorMessage.value = "Erreur de traitement, vueillez réessayer plus tard."
            }
        }
    };

    const onCheckRegisterCode = async () => {
        try {
            loading.value = true;
            console.log(formData.value)
            let response = await axios.post("/email/code/check", formData.value)
            if (response.status === 200) {
                loading.value = false;
                sessionStorage.setItem('userInfo', JSON.stringify(response.data.data.user))
                sessionStorage.removeItem('email')
                router.push({ name: 'Dashboard' })
            }
        } catch (error) {
            loading.value = false
            store.commit('SET_ERROR', true)
            if(error.response.status === 401) {
                errorMessage.value = error.response.data.message
            } 
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
                errorMessage.value = "Erreur de traitement, vueillez réessayer plus tard."
            }
        }
    };

    const onSendPasswordCode = async () => {
        try {
            loading.value = true;
            let response = await axios.post("/password/forgot", formData.value)
            if (response.status === 200) {
                loading.value = false;
                console.log(response.data)
                sessionStorage.setItem('email', formData.value.email)
                router.push('/confirmation-code')
            }
        } catch (error) {
            loading.value = false
            store.commit('SET_ERROR', true)
            if(error.response.status === 401) {
                errorMessage.value = error.response.data.message
            } 
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
                errorMessage.value = "Erreur de traitement, vueillez réessayer plus tard."
            }
        }
    }

    const onCheckForgotCodeConfirmation = async () => {
        try {
            loading.value = true;
            let response = await axios.post("/password/code/check", formData.value)
            if (response.status === 200) {
                loading.value = false;
                isValid.value = true;
            }
        } catch (error) {
            loading.value = false
            store.commit('SET_ERROR', true)
            if(error.response.status === 401) {
                errorMessage.value = error.response.data.message
            } 
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
                errorMessage.value = "Erreur de traitement, vueillez réessayer plus tard."
            }
        }
    }

    const onGetAuthUser = async () => {
        try {
            store.commit('SET_LOADING', true)
            let response = await axios.get("/auth_user")
            if (response.status === 200) {
                store.commit('SET_LOADING', false)
                user.value = response.data.data.user;
                sessionStorage.setItem('userInfo', JSON.stringify(response.data.data.user))
            }
        } catch (error) {
            store.commit('SET_LOADING', false)
            // store.commit('SET_ERROR', true)
            if(error.response.status === 401) {
                errorMessage.value = error.response.data.message
            } 
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
                errorMessage.value = "Erreur de traitement, vueillez réessayer plus tard."
            }
        }
    }

    const onResetPassword = async () => {
        try {
            loading.value = true;
            let response = await axios.post("/password/reset", formData.value)
            if (response.status === 200) {
                loading.value = false;
                isValid.value = true;
                sessionStorage.removeItem('email')
                router.push('/login')
                swal.fire(
					'Mail!',
					'Consulter vos mails.',
					'success'
				)
            }
        } catch (error) {
            loading.value = false
            store.commit('SET_ERROR', true)
            if(error.response.status === 401) {
                errorMessage.value = error.response.data.message
            } 
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
                errorMessage.value = "Erreur de traitement, vueillez réessayer plus tard."
            }
        }
    }

    const onUpdatePassword = async () => {
        try {
            loading.value = true;
            let response = await axios.put("/password/update", formData.value)
            if (response.status === 200) {
                loading.value = false;
                isValid.value = true;
                sessionStorage.removeItem('email')
                swal.fire(
					'Modifié!',
					'Mot de passe modifié avec succès.',
					'success'
				)
            }
        } catch (error) {
            loading.value = false
            store.commit('SET_ERROR', true)
            if(error.response.status === 401) {
                errorMessage.value = error.response.data.message
            } 
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
                errorMessage.value = "Erreur de traitement, vueillez réessayer plus tard."
            }
        }
    }

    const onSendEmailVerifyCode = async () => {
        try {
            loading.value = true;
            let response = await axios.post("/email/verify", formData.value)
            if (response.status === 200) {
                loading.value = false;
                successMessage.value = response.data.data.message
                showMessage.value = true;
            }
        } catch (error) {
            loading.value = false
            store.commit('SET_ERROR', true)
            if(error.response.status === 401) {
                errorMessage.value = error.response.data.message
            } 
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
                errorMessage.value = "Erreur de traitement, vueillez réessayer plus tard."
            }
        }
    }

    const onLogout = async () => {
        try {
            loading.value = true;
            let response = await axios.post("/logout")
            if (response.status === 200) {
                loading.value = false;
                sessionStorage.removeItem('_token')
                sessionStorage.removeItem('userInfo')
                sessionStorage.removeItem('email')
                sessionStorage.clear()
                router.push('/login')
                swal.fire({
                    icon: 'error',
                    type: 'error',
                    toast: true,
                    position: 'top-right',
                    title: 'Deconnexion....',
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true
                })
            }
        } catch (error) {
            loading.value = false
            store.commit('SET_ERROR', true)
            if(error.response.status === 401) {
                errorMessage.value = error.response.data.message
            } 
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
                errorMessage.value = "Erreur de traitement, vueillez réessayer plus tard."
            }
        }
    }

    const onNewPassword = async () => {
        try {
            loading.value = true;
            
            let response = await axios.put("/password/update", formData.value)
            if (response.status === 200) {
                loading.value = false;
                router.push('/login')
                console.log(response.data)
            }
        } catch (error) {
            loading.value = false
            store.commit('SET_ERROR', true)
            if(error.response.status === 401) {
                errorMessage.value = error.response.data.message
            } 
            else if (error.response.status === 422) {
				errorMessage.value = error.response.data.message;
				if(error.response.data.errors) {
					errors.value = error.response.data.errors;
				}
			} 
            else {
                errorMessage.value = "Erreur de traitement, vueillez réessayer plus tard."
            }
        }
    }

	return {
		formData,
		loading,
        userId,
        user,
        errorMessage,
        isValid,
        errors,
        showMessage,
        successMessage,
		onLogin,
        onRegister,
        onCheckRegisterCode,
        onSendPasswordCode,
        onCheckForgotCodeConfirmation,
        onResetPassword,
        onSendEmailVerifyCode,
        onLogout,
        onGetAuthUser,
        onNewPassword,
        onUpdatePassword
	};
}
