import DefaultLayout from "@/components/layouts/defaultLayout/Default.vue"
import auth from '../middleware/auth';
import verified from '../middleware/verified';

const routes = [
    {
        path: '/',
        component: DefaultLayout,
        children: [
            {
                path: "/",
                redirect: "dashboard",
            },
            {
                path: '/dashboard',
				name: 'dashboard',
				component:  () => import('../views/HomeView.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
				path: '/profil',
				name: "profil",
				component: () => import('../views/Auth/Profil.vue'),
                meta: {
					middleware: [auth, verified]
				}
			}
        ]
    }
];
export default routes