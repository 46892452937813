export default {
    namespaced: true,
    state: {
		openCreateProduit: false,
		openCreateFundType: false,
		openEditFundType: false,
		openEditFund: false,
		openFundCriteria: false,
		openEditFundCriteria: false,
		infoFund: null,
		fundType: null,
		productsFunds: [],
		intervenantsList: [],
		porteursList: [],
		partners: [],
		directions: [],
		openAddIntervenant: false,
		openAddPorteur: false,
		reloadData: false,
		reloadFundType: false,

		//docuement
		openAddDocuement: false,

		// Poles
		openAddPole: false,

		// Gender 
		openAddGender: false,

		// Document type 
		openAddDocType: false
	},
	mutations: {
		SET_OPEN_NEW_PRODUIT(state, payload) {
			state.openCreateProduit = payload
		},
		SET_OPEN_EDIT_FUND(state, payload) {
			state.openEditFund = payload
		},
		SET_OPEN_CRITERIA_FUND(state, payload) {
			state.openFundCriteria = payload
		},
		SET_OPEN_EDIT_CRITERIA_FUND(state, payload) {
			state.openEditFundCriteria = payload
		},
		SET_INFO_FUND(state, payload) {
			state.infoFund = payload
		},
		SET_FUNDTYPE_VALUE(state, payload) {
			state.fundType = payload
		},
		SET_OPEN_FUND_TYPES(state, payload) {
			state.openCreateFundType = payload
		},
		SET_EDIT_FUND_TYPES(state, payload) {
			state.openEditFundType = payload
		},
		SET_OPEN_NEW_INTERVENANT(state, payload) {
			state.openAddIntervenant = payload
		},
		SET_OPEN_NEW_PORTEUR(state, payload) {
			state.openAddPorteur = payload
		},
		SET_PRODUCTS_FUNDS(state, payload) {
			if(state.productsFunds.length != 0) {
				state.productsFunds = state.productsFunds.concat(payload)
			}
			else {
				state.productsFunds = payload
			}
		},
		SET_REMOVE_PRODUCTS(state, payload) {
			state.productsFunds.splice(payload, 1)
		}, 
		SET_INTERVENANTS_FUNDS(state, payload) {
			state.intervenantsList.push(payload);
		},
		SET_REMOVE_INTERVENANTS(state, payload) {
			state.intervenantsList.splice(payload, 1)
		},
		SET_PORTEURS_FUNDS(state, payload) {
			state.porteursList.push(payload);
		},
		SET_REMOVE_PORTEUR(state, payload) {
			state.porteursList.splice(payload, 1)
		},
		SET_SAVE_INTERVENANTS(state, payload) {
			if(payload == true) {
				state.intervenantsList.forEach(element => {
					if(element.direction_id) {
						state.directions.push({
							direction_id: element.direction_id,
							role: element.role
						})
					}
					if(element.partner_id) {
						state.partners.push({
							partner_id: element.partner_id,
							role: element.role
						})
					}
				});
				state.porteursList.forEach(element => {
					if(element.direction_id) {
						state.directions.push({
							direction_id: element.direction_id,
							role: element.role
						})
					}
					if(element.partner_id) {
						state.partners.push({
							partner_id: element.partner_id,
							role: element.role
						})
					}
				});

			}
		},
		SET_RELOAD_DATA(state, payload) {
			state.reloadData = payload
		},
		SET_RELOAD_FUNDTYPE(state, payload) {
			state.reloadFundType = payload
		},

		//Document Mutation 
		SET_ADD_DOCUMENT(state, payload) {
			state.openAddDocuement = payload
		},

		// Pole mutations 
		SET_ADD_POLE(state, payload) {
			state.openAddPole = payload
		},

		// Gender mutations 
		SET_ADD_GENDER(state, payload) {
			state.openAddGender = payload
		},

		// Gender mutations 
		SET_ADD_DOC_TYPE(state, payload) {
			state.openAddDocType = payload
		}

	},
	actions: {},
}