export default {
    namespaced: true,
    state: {
		openCreateProduct: false,
		openDetailProduct: false,
		openEditProduct: false,
		openAddSector: false,
		openAddFamily: false,
		openAddQuestion: false,
		openEditSector: false,
		openEditSubSector: false,
		openEditFamily: false,
		openAddSubSector: false,
		openAddInvestigationSheet: false,
		reloadData: false,
		reloadSector: false,
		reloadSubSector: false,
		reloadFamily: false,
		product: null,
		sector: null,
		family: null,
		surveySheet: null,
		subSector: null,
		products: [],
		domains: [],
		sectors: [],
		subSectors: [],
		famillies: [],
		productsFunds: [],
	},
	mutations: {
		SET_OPEN_NEW_PRODUCT(state, payload) {
			state.openCreateProduct = payload
		},
		SET_OPEN_ADD_SECTOR(state, payload) {
			state.openAddSector = payload
		},
		SET_OPEN_ADD_SUBSECTOR(state, payload) {
			state.openAddSubSector = payload
		},
		SET_OPEN_ADD_QESTION(state, payload) {
			state.openAddQuestion = payload
		},
		SET_OPEN_ADD_FAMILY(state, payload) {
			state.openAddFamily = payload
		},
		SET_OPEN_ADD_INVESTIGATIONSHEET(state, payload) {
			state.openAddInvestigationSheet = payload
		},
        SET_OPEN_DETAIL_PRODUCT(state, payload) {
            state.openDetailProduct = payload
        },
		SET_OPEN_EDIT_PRODUCT(state, payload) {
			state.openEditProduct = payload
		},
		SET_OPEN_EDIT_SECTOR(state, payload) {
			state.openEditSector = payload
		},
		SET_OPEN_EDIT_SUBSECTOR(state, payload) {
			state.openEditSubSector = payload
		},
		SET_OPEN_EDIT_FAMILY(state, payload) {
			state.openEditFamily = payload
		},
		SET_RELOAD_DATA(state, payload) {
			state.reloadData = payload
		},
		SET_RELOAD_SECTOR(state, payload) {
			state.reloadSector = payload
		},
		SET_RELOAD_SUBSECTOR(state, payload) {
			state.reloadSubSector = payload
		},
		SET_RELOAD_FAMILY(state, payload) {
			state.reloadFamily = payload
		},
		SET_PRODUCT_VALUE(state, payload) {
			state.product = payload
		},
		SET_SECTOR_VALUE(state, payload) {
			state.sector = payload
		},
		SET_SUBSECTOR_VALUE(state, payload) {
			state.subSector = payload
		},
		SET_FAMILY_VALUE(state, payload) {
			state.family = payload
		},
		SET_SURVEYSHEET_VALUE(state, payload) {
			state.surveySheet = payload
		},
		SET_GET_FAMILLIES(state, payload) {
			state.famillies = payload
		},
		SET_PRODUCTS_LIST(state, payload) {
			state.products = payload
		},
		SET_SECTORS_LIST(state, payload) {
			state.sectors = payload
		},
		SET_SUB_SECTORS_LIST(state, payload) {
			state.subSectors = payload
		},
		SET_FAMILLIES_LIST(state, payload) {
			state.famillies = payload
		},
		SET_DOMAINS_LIST(state, payload) {
			state.domains = payload
		},
		SET_PRODUCTS_FUNDS(state, payload) {
			if(state.productsFunds.length != 0) {
				state.productsFunds = state.productsFunds.concat(payload)
			}
			else {
				state.productsFunds = payload
			}
		},
	},
	actions: {},
}