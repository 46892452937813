import auth from '../middleware/auth';
import verified from '../middleware/verified';
import DefaultLayout from "@/components/layouts/defaultLayout/Default.vue";

const routes = [
    {
        path: '/funds',
        component: DefaultLayout,
        children: [
            {
                path: '/funds',
                name: 'funds.index',
                component: () => import('../views/funds/ListFunds.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/funds/create',
                name: 'funds.create',
                component: () => import('../views/funds/CreateFund.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/funds/:id',
                name: 'funds.show',
                component: () => import('../views/funds/ShowFund.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/fund-type',
                name: 'fundTypes.index',
                component: () => import('../views/admin/typeFunds/TypeFund.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
        ]
    }
];

export default routes;