export default {
    namespaced: true,
    state: {
		_token: null,
		userInfo: null,
		user: null,
		email: null,
		roles: null,
		ministries: null,
		typePartners: null,
		openCreateUser: false,
		openEditUser: false,
		openEditProfile: false,
		showUser: false,
		showMessageSuccess: false,
		reloadData: false,
	},
	getters: {
		getToken(state) {
			return state._token
		},
		getUserInfo(state) {
			return state.userInfo
		},
		getEditUser(state) {
			return state.user
		},
		getEmail(state) {
			return state.email
		},
		getOpenCreateUser(state) {
			return state.openCreateUser
		},
		getOpenEditUser(state) {
			return state.openEditUser
		},
		getShowMessageSuccess(state)  {
			return state.showMessageSuccess
		},
		getReloadUser(state) {
			return state.reloadData
		}
	},
	mutations: {
		SET_TOKEN(state, payload) {
			state._token = payload
		},
		SET_USER_INFO(state, payload) {
			state.userInfo = payload
		},
		SET_EDIT_USER(state, payload) {
			state.user = payload
		},
		SET_EMAIL(state, payload) {
			state.email = payload
		},
		SET_OPEN_NEW_USER(state, payload) {
			state.openCreateUser = payload
		},
		SET_RELOAD_USER(state, payload) {
			state.reloadData = payload
		},
		SET_OPEN_EDIT_USER(state, payload) {
			state.openEditUser = payload
		},
		SET_SHOW_USER(state, payload)  {
			state.showUser = payload
		},
		SET_OPEN_EDIT_PROFILE(state, payload) {
			state.openEditProfile = payload
		},
		SET_USER_ROLES(state, payload) {
			state.roles = payload
		},
		SET_USER_MINISTRIES(state, payload) {
			state.ministries = payload
		},
		SET_USER_TYPEPARTNERS(state, payload) {
			state.typePartners = payload
		}
	},
}