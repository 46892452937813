export default function auth({ next, router }) {
	const verifiedEmail = JSON.parse(sessionStorage.getItem('userInfo'));
    const token = sessionStorage.getItem('_token');
	if (!token) {
		return router.push({ name: 'login' });
	}
	if (!verifiedEmail.email_verified_at) {
		return router.push({ name: 'confirmRegister' });
	}

	return next();
}
