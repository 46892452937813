export default {
    namespaced: true,
    state: {
		openCreateProduit: false,
		openEditProject: false,
		reloadData: false,
		projectInfo: null,
		// document 
		openAddDocument: false,
	},
	mutations: {
		SET_OPEN_NEW_PRODUIT(state, payload) {
			state.openCreateProduit = payload
		},
		SET_OPEN_EDIT_PROJECT(state, payload) {
			state.openEditProject = payload
		},
		SET_PROJECT_INFO(state, payload) {
			state.projectInfo = payload
		},
		SET_RELOAD_DATA(state, payload) {
			state.reloadData = payload
		},

		// Document mutations
		SET_OPEN_ADD_DOC(state, payload) {
			state.openAddDocument = payload
		},
	},
	actions: {},
}