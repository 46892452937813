export default {
    namespaced: true,
    state: {
		openCreateDocument: false,
		reloadData: false,
	},
	mutations: {
		SET_OPEN_NEW_DOCUMENT(state, payload) {
			state.openCreateDocument = payload
		},
        SET_OPEN_DETAIL_AGGREGATE(state, payload) {
            state.openDetailAggregate = payload
        },
		SET_RELOAD_DATA(state, payload) {
			state.reloadData = payload
		}
	},
	actions: {},
}