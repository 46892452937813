import { createStore } from "vuex";
import entreprises from './modules/entreprises'
import users from './modules/users'
import membres from "./modules/membres";
import funds from './modules/funds';
import projects from "./modules/projects";
import productSheets from "./modules/productSheets";
import aggregates from "./modules/aggregates";
import documents from "./modules/documents";
import products from "./modules/products";
import admin from "./modules/admin"

export default createStore({
  state: {
		isError: false,
		loading: false,
	},
	getters: {
		getError(state) {
			return state.isError
		}
	},
	mutations: {
		SET_ERROR(state, payload) {
			state.isError = payload
		},
		SET_LOADING(state, payload) {
			state.loading = payload
		}
	},
  actions: {},
  modules: {
    entreprises,
		users,
		membres,
		funds,
		projects,
		productSheets,
		aggregates,
		documents,
		products,
		admin
  },
});
