export default {
    namespaced: true,
    state: {
		openCreateMember: false,
		reloadData: false,
		openDetailMembre: false,
		openEditMembre: false,
		membre: null,
		jobsList: null,
	},
	mutations: {
		SET_OPEN_NEW_MEMBER(state, payload) {
			state.openCreateMember = payload
		},
        SET_OPEN_DETAIL_MEMBER(state, payload) {
            state.openDetailMembre = payload
        },
		SET_RELOAD_DATA(state, payload) {
			state.reloadData = payload
		},
		SET_OPEN_EDIT_MEMBRE(state, payload) {
			state.openEditMembre = payload
		},
		SET_MEMBRE_VALUE(state, payload) {
			state.membre = payload
		},
		SET_JOBS_LIST(state, payload) {
			state.jobsList = payload
		},
	},
	actions: {},
}