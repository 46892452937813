<template>
    <div>
        <div class="main-menu menu-fixed menu-light menu-accordion menu-shadow expanded" data-scroll-to-active="true"
            style="touch-action: none; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
            <div class="navbar-header expanded" style="height: 80">
                <ul class="nav navbar-nav flex-row">
                    <li class="nav-item me-auto">
                        <a class="" href="/">
                            <img src="../../../../assets/images/logo/logo.png" class="mt-n3" alt="" width="185" height="78">
                        </a>
                    </li>
                    <li class="nav-item nav-toggle">
                        <a class="nav-link modern-nav-toggle pe-0" data-bs-toggle="collapse">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-x d-block d-xl-none text-primary toggle-icon font-medium-4">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-disc d-none d-xl-block collapse-toggle-icon primary font-medium-4">
                                <circle cx="12" cy="12" r="10"></circle>
                                <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="shadow-bottom"></div>
            <div class="main-menu-content ps ps--active-y">
                <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                    <!-- <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">Bénéficiaire</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-more-horizontal">
                            <circle cx="12" cy="12" r="1"></circle>
                            <circle cx="19" cy="12" r="1"></circle>
                            <circle cx="5" cy="12" r="1"></circle>
                        </svg>
                    </li>

                    <li class="nav-item">
                        <router-link class="d-flex align-items-center" to="/dashboard">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-gauge"></i>
                                Tableau de board
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="d-flex align-items-center" to="/">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-building"></i>
                                Mes entreprises
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="d-flex align-items-center" to="/">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-money-bill-1-wave"></i>
                                Mes fonds
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="d-flex align-items-center" to="/">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-file"></i>
                                Mes documents
                            </span>
                        </router-link>
                    </li> -->
                    <li class=" navigation-header"><span data-i18n="User Interface">Réglages</span><svg
                            xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-more-horizontal">
                            <circle cx="12" cy="12" r="1"></circle>
                            <circle cx="19" cy="12" r="1"></circle>
                            <circle cx="5" cy="12" r="1"></circle>
                        </svg>
                    </li>
                    <li class=" nav-item">
                        <router-link class="d-flex align-items-center" :to="{ name: 'structures.index'}">
                            <span class="menu-title text-truncate">
                                <i class="fa-regular fa-building"></i>
                                Structures</span>
                        </router-link>
                    </li>

                    <li class=" nav-item">
                        <router-link class="d-flex align-items-center" :to="{ name: 'funds.index' }">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-money-bill-wave"></i>
                                Fonds
                            </span>
                        </router-link>
                    </li>

                    <li class=" nav-item">
                        <router-link class="d-flex align-items-center" :to="{ name: 'projects.index' }">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-diagram-project"></i>
                                Projets
                            </span>
                        </router-link>
                    </li>

                    <li class=" nav-item">
                        <router-link class="d-flex align-items-center" :to="{ name: 'users.index' }">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-users"></i>
                                Utilisateurs
                            </span>
                        </router-link>
                    </li>

                    <!-- <li class=" nav-item">
                        <router-link class="d-flex align-items-center" to="/">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-diagram-project"></i>
                                Projects
                            </span>
                        </router-link>
                    </li>
                    <li class=" nav-item">
                        <router-link class="d-flex align-items-center" to="/">
                            <span class="menu-title text-truncate">
                                <i class="fas fa-users"></i>
                                Utilisateurs
                            </span>
                        </router-link>
                    </li>
                    <li class=" nav-item">
                        <router-link class="d-flex align-items-center" to="/">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-mattress-pillow"></i>
                                Workflows
                            </span>
                        </router-link>
                    </li>
                    <li class=" nav-item">
                        <router-link class="d-flex align-items-center" to="/">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-database"></i>
                                Données
                            </span>
                        </router-link>
                    </li>
                    <li class=" nav-item">
                        <router-link class="d-flex align-items-center" to="/">
                            <span class="menu-title text-truncate">
                                <i class="fa-solid fa-network-wired"></i>
                                Iots
                            </span>
                        </router-link>
                    </li> -->
                    <li class="nav-item has-sub" style="">
                        <a class="d-flex align-items-center" href="#">
                            <i class="fas fa-gear"></i>
                            <span class="menu-title text-truncate" data-i18n="Invoice">Réglages</span>
                        </a>
                    <ul class="menu-content">
                        <li>
                            <router-link class="d-flex align-items-center" :to="{ name: 'products.index'}">
                                <i class="fa-brands fa-product-hunt"></i>
                                <span class="menu-item text-truncate" data-i18n="List">Spéculations</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="d-flex align-items-center" :to="{ name: 'secteurs.index'}">
                                <i class="fa-solid fa-map-pin"></i>
                                <span class="menu-item text-truncate" data-i18n="List">Secteurs</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="d-flex align-items-center" :to="{ name: 'poles.index'}">
                                <i class="fas fa-map-location"></i>
                                <span class="menu-item text-truncate" data-i18n="Preview">Pôles</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="d-flex align-items-center" :to="{ name: 'fundTypes.index'}">
                                <!-- <i class="fas fa-map-location"></i> -->
                                <i class="fa-solid fa-money-bill-1-wave"></i>
                                <span class="menu-item text-truncate" data-i18n="Preview">Type de fonds</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="d-flex align-items-center" :to="{ name: 'legal.index'}">
                                <i class="fa-solid fa-scale-balanced"></i>
                                <span class="menu-item text-truncate" data-i18n="Preview">Elements juridiques</span>
                            </router-link>
                        </li>
                        <li>
                            <a class="d-flex align-items-center" href="app-invoice-edit.html">
                                <i class="fa-solid fa-star-half-stroke"></i>
                                <span class="menu-item text-truncate" data-i18n="Edit">Pondérations</span>
                            </a>
                        </li>
                    </ul>
                </li>
                </ul>
                <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                    <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                </div>
                <div class="ps__rail-y" style="top: 0px; height: 811px; right: 0px;">
                    <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 362px;"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.main-menu .navbar-header {
    height: 5.45rem;
}
</style>