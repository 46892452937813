import auth from '../../middleware/auth';
import verified from '../../middleware/verified';

const routes = [
    {
        path: '/secteurs',
        component: () => import('../../components/layouts/defaultLayout/Default.vue'),
        children: [
            {
                path: '/secteurs',
                name: 'secteurs.index',
                component: () => import('../../views/admin/secteurs/Sectors.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            // {
            //     path: '/secteurs/create',
            //     name: 'secteurs.create',
            //     component: () => import('../../views/admin/secteurs/CreatePole.vue'),
            //     meta: {
			// 		middleware: [auth, verified]
			// 	}
            // },
        ]
    }
];

export default routes;