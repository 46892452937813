import auth from '../../middleware/auth';
import verified from '../../middleware/verified';

const routes = [
    {
        path: '/products',
        component: () => import('../../components/layouts/defaultLayout/Default.vue'),
        children: [
            {
                path: '/products',
                name: 'products.index',
                component: () => import('../../views/admin/speculations/Products.vue'),
                meta: {
					middleware: [auth, verified]
				}
            },
            {
                path: '/survey-sheets/:id',
                name: 'surveySheets.show',
                component: () => import('../../views/admin/speculations/SurveySheet.vue'),
                meta: {
					middleware: [auth, verified]
				}
            }
        ]
    }
];

export default routes;