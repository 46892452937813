export default {
    namespaced: true,
    state: {
		openCreateAggregate: false,
		reloadData: false,
		aggregate: null,
		openEditAggreate: false,
	},
	mutations: {
		SET_OPEN_NEW_AGGREGATE(state, payload) {
			state.openCreateAggregate = payload
		},
        SET_OPEN_DETAIL_AGGREGATE(state, payload) {
            state.openDetailAggregate = payload
        },
		SET_RELOAD_DATA(state, payload) {
			state.reloadData = payload
		},
		SET_OPEN_EDIT_AGGREGATE(state, payload) {
			state.openEditAggreate = payload
		},
		SET_AGGREGATE_VALUE(state, payload) {
			state.aggregate = payload
		}
	},
	actions: {},
}